import "./App.css";
import HeroPage from "./pages/homePage/heroPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "./pages/homePage/imagesSlider.js";
import AboutPage from "./pages/homePage/aboutPage/index.js";
import OurFeaturePage from "./pages/homePage/ourFeaturePage/index.js";
import OurServices from "./pages/homePage/ourServices/index.js";
import ModelPage from "./pages/homePage/modelPage/index.js";
import CeoMessage from "./pages/homePage/ceoMessage/index.js";
import TeamCarousel from "./pages/homePage/teamMembers/index.js";
import GEtInTouch from "./pages/homePage/getinTouch/index.js";
import Footer from "./components/footer/index.js";
import Header from "./components/header/index.js";
import FormModalData from "./components/FormModal/FormModalData.js";
import { useState, useEffect } from "react";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <FormModalData isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
      <div className="overflow-x-hidden">
        <Header setIsModalOpen={setIsModalOpen} />
        <div id="home">
          <HeroPage setIsModalOpen={setIsModalOpen} />
        </div>
        <div id="image-slider">
          <ImageSlider />
        </div>
        <div id="about">
          <AboutPage />
        </div>
        <div id="features">
          <OurFeaturePage />
        </div>
        {/* <div id="services" className="container-fluid">
          <OurServices />
        </div> */}
        <div id="model">
          <ModelPage />
        </div>
        <div id="ceo-message">
          <CeoMessage />
        </div>
        <div id="team">
          <TeamCarousel />
        </div>
        {/* <div className="getintouch">
          <GEtInTouch />
        </div> */}
        <Footer />
      </div>
    </>
  );
}

export default App;
