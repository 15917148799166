import React, { useState } from "react";
import Logo from "../../assets/Images/logo.svg";
import { Link } from "react-scroll";

const Header = ({ setIsModalOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="bg-[#0A0909] border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4 ps-8 pe-3 md:pe-16">
        <Link
          to="home"
          smooth={true}
          duration={500}
          className="flex items-center space-x-2 rtl:space-x-reverse cursor-pointer"
        >
          <img
            src={Logo}
            alt=""
            className="w-[80px] h-[80px] md:w-[100px] md:h-[90px] xl:w-[125px] xl:h-[102px]"
          />
          <div className="pt-3">
            <span className="self-center tracking-extra-wide text-[14px] sm:text-[15px]  md:text-[19px] xl:text-[22px] font-[400] ms-[-5px] whitespace-nowrap text-white leading-4">
              CONSTRUCTECH
            </span>
            <p className="self-center text-[14px] sm:text-[14.5px] md:text-[17px] xl:text-[19px] font-[400] ms-[-5px] whitespace-nowrap text-white">
              Estimating and Planning
            </p>
          </div>
        </Link>
        <button
          onClick={toggleMenu}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          {isMenuOpen ? (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          )}
        </button>
        <div
          className={`${isMenuOpen ? "block" : "hidden"} w-full lg:block lg:w-auto relative z-20 left-[-10px]`}
          id="navbar-default"
        >
          <ul className="font-medium gap-2 flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 0  ">
            <Link
              to="about"
              smooth={true}
              duration={500}
              className="block px-3 mx-1 text-white text-[16px] xl:text-lg font-normal cursor-pointer md:bg-transparent md:text-white md:p-0 dark:text-white hover:text-[#F4A31D] border-b-4 border-transparent hover:border-[#F4A31D]"
              onClick={closeMenu}
            >
              <li>About us</li>
            </Link>
            <Link
              to="features"
              smooth={true}
              duration={500}
              className="block px-3 mx-1 text-white text-[16px] xl:text-lg font-normal cursor-pointer md:bg-transparent md:text-white md:p-0 dark:text-white hover:text-[#F4A31D] border-b-4 border-transparent hover:border-[#F4A31D]"
              onClick={closeMenu}
            >
              <li>Features</li>
            </Link>
            {/* <li>
              <Link
                to="services"
                smooth={true}
                duration={500}
                className="block px-3 mx-1 text-white text-[16px] xl:text-lg font-normal cursor-pointer md:bg-transparent md:text-white md:p-0 dark:text-white hover:text-[#F4A31D] border-b-4 border-transparent hover:border-[#F4A31D]"
                onClick={closeMenu}
              >
                Services
              </Link>
            </li> */}
            <li>
              <Link
                to="model"
                smooth={true}
                duration={500}
                className="block px-3 mx-1 text-white text-[16px] xl:text-lg font-normal cursor-pointer md:bg-transparent md:text-white md:p-0 dark:text-white hover:text-[#F4A31D] border-b-4 border-transparent hover:border-[#F4A31D]"
                onClick={closeMenu}
              >
                Process
              </Link>
            </li>
            <li>
              <Link
                to="getintouch"
                smooth={true}
                duration={500}
                className="block px-3 mx-1 text-white text-[16px] xl:text-lg font-normal cursor-pointer md:bg-transparent md:text-white md:p-0 dark:text-white hover:text-[#F4A31D] border-b-4 border-transparent hover:border-[#F4A31D]"
                onClick={() => {
                  closeMenu();
                  setIsModalOpen(true);
                }}
              >
                Get a Quote
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
