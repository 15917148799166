import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import Linkedin from "../../../assets/Images/linkedin.svg";
import { teamMembers } from "../../../constant/teamMembers";
import ScrollText from "../../../assets/Images/scroll.png";
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="relative">
      <div
        className="absolute top-[-481px] right-4 transform -translate-y-1/2 bg-white border-2 border-[#F4A31D] rounded-full p-3 cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="5" y1="12" x2="19" y2="12" />
          <polyline points="12 5 19 12 12 19" />
        </svg>
      </div>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="relative">
      <div
        className="absolute top-[-78px] right-[86px] transform -translate-y-1/2 bg-white border-2 border-[#F4A31D] rounded-full p-3 cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="19" y1="12" x2="5" y2="12" />
          <polyline points="12 19 5 12 12 5" />
        </svg>
      </div>
    </div>
  );
};
const marqueeStyle = {
  display: "inline-block",
  whiteSpace: "nowrap",
  animation: "marquee 20s linear infinite",
};
const TeamCarousel = () => {
  const slideVariants = {
    hidden: { opacity: 0, y: -100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const spanVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { delay: 0.3, duration: 0.8, ease: "easeOut" },
    },
  };

  return (
    <>
      <motion.div
        className="relative mx-auto px-[20px] sm:px-[40px] md:px-[120px] py-16 mt-14"
        initial="hidden"
        animate="visible"
      >
        <div className="flex ps-[40px] sm:ps-[100px] pb-16 mb-3">
          <motion.span
            className="h-[.3px] w-16 bg-[#000000] mt-4 me-5"
            variants={spanVariant}
          ></motion.span>
          <motion.h1
            className="text-[#000000] text-[20px] font-normal font-poppins"
            variants={slideVariants}
          >
            OUR TEAM
          </motion.h1>
        </div>
        <div className="flex flex-col items-center mb-8">
          <motion.h2
            className="text-start text-[27px] sm:text-[30px] md:text-[37px] font-normal font-timesNewRoman ps-2"
            variants={slideVariants}
          >
            Construtech isn’t a team it’s a family
          </motion.h2>
          <motion.p
            className="text-start text-[16px] sm:text-[18px] text-[#000000] pt-4 pb-[90px] sm:pb-8 ps-3 w-[80%] sm:w-[50%]"
            variants={slideVariants}
          >
            Welcome to our digital team, pushing ideas all the way
          </motion.p>
        </div>
        <Slider
          dots={false}
          infinite={true}
          speed={500}
          slidesToShow={3}
          slidesToScroll={1}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {teamMembers?.map((member, index) => (
            <motion.div
              key={index}
              className="px-4 py-8"
              variants={slideVariants}
            >
              <div className="bg-[#D9D9D9] flex flex-col justify-center items-center rounded-lg shadow-lg mx-3 p-6 text-center h-[340px]">
                <img
                  className="w-32 h-32 rounded-full mx-auto mb-4"
                  src={member.image}
                  alt={member.name}
                />
                <h3 className="text-xl font-semibold font-poppins">
                  {member.name}
                </h3>
                <p className="text-gray-600 font-poppins">{member.title}</p>
                <p className="text-gray-600 font-poppins">{member.location}</p>
                <div className="mt-4 flex justify-end w-full">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={member?.href}
                  >
                    <img src={Linkedin} alt="LinkedIn" className="w-8 h-8" />
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </Slider>
      </motion.div>
      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
      <div className="overflow-hidden whitespace-nowrap mb-16 mt-3">
        <div style={marqueeStyle}>
          <p className="outline-text "> ESTIMATE. PLAN. DESIGN</p>
        </div>
      </div>
    </>
  );
};

export default TeamCarousel;
