import Slide1 from "../assets/Images/slide1.png"
import slide2 from "../assets/Images/slide2.png"
import Slide3 from "../assets/Images/slide3.png"
import Slide4 from "../assets/Images/slide4.png"
import slide5 from "../assets/Images/slide5.png"

export const ImagesSlider =[
    {
        img:Slide1,
    },
    {
        img:slide2,
    },
    {
        img:Slide3,
    },
    {
        img:Slide4,
    },
    {
        img:slide5,
    },
]