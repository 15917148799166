import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ModelPoints } from "../../../constant/modelData";

const ModelPage = () => {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const spanControls = useAnimation();
    const contentControls = useAnimation();

    useEffect(() => {
        if (inView) {
            spanControls.start({
                x: 0,
                opacity: 1,
                transition: { duration: 0.8, ease: 'easeOut' },
            });
            contentControls.start({
                y: 0,
                opacity: 1,
                transition: { duration: 0.8,  ease: 'easeOut' },
            });
        } 
    }, [inView, spanControls, contentControls]);

    return (
        <div className="bg-[#0A0909] px-[30px] sm:px-[60px] md:px-[80px] lg:px-[120px] py-12">
            <motion.div className="flex ps-[45px] pb-8 pt-[90px]" ref={ref}>
                <motion.span
                    className="h-[.3px] w-16 bg-[#FFFFFF] mt-4 me-5"
                    initial={{ x: -350, opacity: 0 }}
                    animate={spanControls}
                ></motion.span>
                <motion.h1
                    className="text-[#FFFFFF] text-[20px] font-normal font-poppins"
                    initial={{ y: -400, opacity: 0 }}
                    animate={contentControls}
                >
                    MODEL
                </motion.h1>
            </motion.div>
            <motion.h1
                className="text-[24px] md:text-[33px] lg:text-[35px] xl:text-[37px] font-[400px] ps-[36px] sm:ps-[136px] pb-16 my-12 text-white font-timesNewRoman"
                initial={{ y: -350, opacity: 0 }}
                animate={contentControls}
            >
                Get things done tailored to <br /> your needs
            </motion.h1>
            <motion.div
                className="flex justify-center lg:justify-end w-full mx-12 sm:mx-0"
                initial={{ y: -350, opacity: 0 }}
                animate={contentControls}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 w-[100%]  lg:w-[70%]">
                    {ModelPoints.map((point, index) => (
                        <div
                            key={index}
                            className="p-4 mb-16"
                        >
                            <div className="text-white transition-colors duration-500 hover:text-[#F4A31D]">
                                <div className='flex pb-12'>
                                    <p className='ms-[-70px] pe-5 pt-[2px] flex items-center'>{point.id}</p>
                                    <h2 className="text-[22px] sm:text-[28px] md:text-[30px] lg:text-[33px] font-normal font-timesNewRoman">{point.title}</h2>
                                </div>
                                <p className="text-[20px] mt-[-5px] font-normal w-[90%] sm:w-[75%] font-timesNewRoman">{point.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </motion.div>
        </div>
    );
};

export default ModelPage;
