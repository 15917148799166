export const AboutTabs = [
    {
      title: 'Our Mission',
      content: 'Our mission is to streamline the construction process and deliver accurate, timely, and cost-effective solutions that meet your needs and exceed your expectations.',
    },
    {
      title: 'Our Vision',
      content: 'To be the go-to experts for accurate, reliable construction estimates, helping businesses save time and money while bringing their bids to life.',
    },
    {
      title: 'Our Value',
      content: 'Our values are precision, integrity, and customer success. We are committed to delivering accurate estimates, building trust with every project, and helping businesses achieve their goals efficiently.',
    },
  ];
