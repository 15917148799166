import React from "react";

const FormModalData = ({ isOpen, onClose,setIsModalOpen }) => {

  return (
    <div className="fixed inset-0 z-[99999] h-[100vh] bg-black bg-opacity-50 flex justify-center items-center backdrop-filter backdrop-blur-sm">
      <div className="bg-black h-[100vh] text-white rounded-lg w-11/12 md:w-1/2 lg:w-1/3 p-8 relative">
        <button
          className="absolute top-2 right-2 text-white"
          onClick={() => setIsModalOpen(false)}
        >
          x{" "}
        </button>
        <div className="text-center mb-6">
          <h2 className="text-xl font-semibold mb-2">GET IN TOUCH</h2>
          <p className="text-2xl font-bold">
            Let us tell your story through visuals
          </p>
        </div>
        <form className="space-y-4">
          <div>
            <label className="block mb-1">NAME</label>
            <input
              type="text"
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded"
              placeholder="Your Name"
            />
          </div>
          <div>
            <label className="block mb-1">EMAIL</label>
            <input
              type="email"
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded"
              placeholder="Your Email"
            />
          </div>
          <div>
            <label className="block mb-1">PHONE NUMBER</label>
            <input
              type="tel"
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded"
              placeholder="Your Phone Number"
            />
          </div>
          <div>
            <label className="block mb-1">YOUR MESSAGE</label>
            <textarea
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded"
              placeholder="Your Message"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-yellow-500 text-black rounded hover:bg-yellow-600"
          >
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormModalData;
