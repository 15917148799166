import Person1 from "../assets/Images/team1.png";
import Person2 from "../assets/Images/team2.png";
import CEO from "../assets/Images/ceo.png";
export const teamMembers = [
  {
    name: "Razi Ur Rehman",
    title: "PROCUREMENT OFFICER",
    location: "CONSTRUCTECH - Pakistan",
    image: Person1,
    href: "https://www.linkedin.com/in/razi-ur-rehman-malik-477719219/",
  },
  {
    name: "Rahfay Arshad",
    title: "PROJECT COORDINATOR",
    location: "CONSTRUCTECH - Pakistan",
    image: Person2,
    href: "https://www.linkedin.com/in/rahfay-arshad-75b893210/",
  },
  {
    name: "Syed Samulullah Shah",
    title: "CEO",
    location: "CONSTRUCTECH - Pakistan",
    image: CEO,
    href: "https://www.linkedin.com/in/samiullah3005/",
  },
];
