import Slider from 'react-slick';
import { ImagesSlider } from "../../../constant/sliderImages"
const ImageSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        pauseOnHover: false,
    };

    return (
        <div className='ps-[20px] lg:ps-[120px] bg-[#0A0909] pb-10 pt-10 md:pt-[100px] '>
            <Slider {...settings} className='mt-16 mb-10'>
                {ImagesSlider.map((images, index) => (
                    <div key={index}>
                        <img src={images.img} alt={`slide ${index}`} className="w-[120px] w-[170px] md:w-[220px] lg:w-[270px] xl:w-[315px] pe-4 lg:pe-0" />
                    </div>
                ))}
            </Slider>
        </div>

    )
}
export default ImageSlider;