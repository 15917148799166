import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FeaturesData } from "../../../constant/featurelist";

const OurFeaturePage = () => {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

    const spanControls = useAnimation();
    const divControls = useAnimation();
    const cardControls = useAnimation()
    useEffect(() => {
        if (inView) {
            spanControls.start({
                x: 0,
                opacity: 1,
                transition: { duration: 0.8, ease: 'easeOut' },
            });
            divControls.start({
                y: 0,
                opacity: 1,
                transition: { duration: 0.8, delay: 0.3, ease: 'easeOut' },
            });
            cardControls.start({
                x: 0,
                opacity: 1,
                transition: { duration: 0.8, delay: 0.3, ease: 'easeOut' },
            });
        } 
    }, [inView, spanControls, divControls]);

    return (
        <div className="bg-white px-[30px] sm:px-[60px] md:px-[120px] py-16 mt-10">
            <motion.div className="flex ps-[20px] md:ps-[100px]" ref={ref}>
                <motion.span
                    className="h-[.3px] w-16 bg-[#000000] mt-4 me-5"
                    initial={{ x: -350, opacity: 0 }}
                    animate={spanControls}
                ></motion.span>
                <motion.h1
                    className="text-[20px] font-normal font-poppins"
                    initial={{ y: -400, opacity: 0 }}
                    animate={divControls}
                >
                    OUR FEATURE
                </motion.h1>
            </motion.div>
            <motion.div
                className="flex justify-center w-[100%] pt-[34px]"
                initial={{ y: -400, opacity: 0 }}
                animate={divControls}
            >
                <p className="text-[21px] sm:text-[24px] md:text-[29px] lg:text-[31px] xl:text-[33px] font-[500] font-timesNewRoman text-start md:w-[60%] lg:w-[55%] xl:w-[52%]">
                    We Specialize In Preparing Quantities Take-off, Cost Estimates As Well As Material Takeoffs And Material Lists For Every Construction Trade.
                </p>
            </motion.div>
            <motion.div
                className="flex justify-center pt-16 mt-3 pb-5"
                initial={{ y: -400, opacity: 0 }}
                animate={divControls}
            >
                <p className="text-[17px] md:text-[18px] lg:text-[19px] xl:text-[20px] font-normal text-center w-[90%] md:w-[74%] font-poppins">
                    One Of Our Construction Estimators Will Quantify From The Blueprints, All the Materials & Accessories Needed For Your Trade. It Will Be In Microsoft Excel Sheet Or Any Format You Need, So You Can Send It Out To Bid Quickly. We Specialize in Providing Our Customers With Following Features.
                </p>
            </motion.div>
            <motion.div
                className="text-black py-16"
                initial={{ y: -400, opacity: 0 }}
                animate={divControls}
            >
                <div className="px-4">
                    <div className="flex justify-center lg:justify-end xl:justify-end w-full">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-8 justify-end w-full md:w-[83%] lg:w-[68%] xl:w-[63%]">
                            {FeaturesData.map((item, index) => (
                                <div key={index} className="flex flex-col text-start mb-12">
                                    <div className="flex justify-center items-center bg-[#FFDFAB] p-6 rounded-full mb-4 w-[115px] h-[115px]">
                                   <div>
                                    {item?.icon}
                                   </div>
                                    </div>
                                    <motion.div
                                        className="text-black "
                                        initial={{ x: -400, opacity: 0 }}
                                        animate={cardControls}
                                    >
                                        <div className="text-[17px] md:text-[19px] lg:text-[21px] xl:text-[22px] text-start font-bold mb-2 pt-6 font-timesNewRoman">
                                            {item.title}
                                        </div>
                                        <div className="text-[17px] w-[85%] md:w-[82%] lg:w-[74%] xl:w-[66%] font-poppins">
                                            {item.description}
                                        </div>
                                    </motion.div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default OurFeaturePage;
